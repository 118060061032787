<template>
  <div class="adminMeet_container">
    <div class="adminMeet_head">
      <div class="adminMeet_title">管理员 {{ data.phone }}</div>
      <div class="adminMeet_head_block">
        <div class="adminMeet_head_block_item">
          <img
            class="adminMeet_head_avatar"
            :src="require('@/assets/img/allMeet.png')"
          />

          <div class="adminMeet_head_number">{{ data.all_meet_count }}</div>
          <div class="adminMeet_text">总会议数</div>
        </div>
        <div class="line"></div>
        <div class="adminMeet_head_block_item">
          <img
            class="adminMeet_head_avatar"
            :src="require('@/assets/img/usedMeet.png')"
          />
          <div class="adminMeet_head_number">{{ data.allocated_count }}</div>
          <div class="adminMeet_text">已分配</div>
        </div>
        <div class="line"></div>
        <div class="adminMeet_head_block_item">
          <img
            class="adminMeet_head_avatar"
            :src="require('@/assets/img/noMeet.png')"
          />
          <div class="adminMeet_head_number">{{ data.unallocated_count }}</div>
          <div class="adminMeet_text">未分配</div>
        </div>
      </div>
    </div>
    <div class="adminMeet_main">
      <div class="adminMeet_main_head">
        <div class="adminMeet_title">我的专家</div>
        <div
          @click="addExport()"
          style="display: flex; justify-content: center; align-items: center"
        >
          <span class="adminMeet_text" style="margin-right: 10px"
            >新增专家</span
          >
          <img
            class="adminMeet_head_avatar"
            :src="require('@/assets/img/add.png')"
          />
        </div>
      </div>
      <div class="adminMeet_block">
        <div class="meetstarttable">
          <div class="meettablethead">
            <div class="meettwo">姓名</div>
            <div class="meettwo">总会议数</div>
            <div class="meetone">已预约</div>
            <div class="meetone">已召开</div>
            <div class="meettwo">操作</div>
          </div>

          <div style="height: 100%; overflow: auto">
            <div
              v-if="!data?.doctor_data.length"
              style="
                text-align: center;
                font-size: 20px;
                color: #ccc;
                margin-top: 10px;
              "
            >
              暂无数据
            </div>

            <div
              v-else
              class="meettabletbody"
              v-for="(item, index) in data?.doctor_data"
              :key="index"
            >
              <div class="meettabletr">
                <div class="meettabletr_info">
                  <div class="meettwo">{{ item.name }}</div>
                  <div class="meettwo">{{ item.meet_count }}</div>
                  <div class="meetone">{{ item.booked_num }}</div>
                  <div class="meetone">{{ item.convened_num }}</div>
                  <div class="meettwo">
                    <CustomButton
                      style="padding: 0"
                      size="small"
                      @click="goToDetail(item)"
                      >查看详情
                    </CustomButton>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="adminMeet_foot">
      <div class="adminMeet_foot_btn" @click="goToWeeklyHome">
        <img
          class="adminMeet_foot_btn_icon"
          :src="require('@/assets/img/homing.png')"
          alt=""
        />
        <div>周刊首页</div>
      </div>
      <div class="adminMeet_foot_btn" @click="outLogin">
        <img
          class="adminMeet_foot_btn_icon"
          :src="require('@/assets/img/outLogin.png')"
          alt=""
        />
        <div>退出登录</div>
      </div>
    </div>
  </div>
</template>

<script>
import CustomButton from "@/components/unit/CustomButton";
export default {
  name: "adminMeet",
  components: { CustomButton },
  data() {
    return {
      data: {
        doctor_data: [],
      },
    };
  },
  created() {},
  mounted() {
    this.loadData();
  },
  methods: {
    async loadData() {
      console.log("11111 :>> ", 11111);
      let url = this.$tools.getURL(this.$urls.sale.home, {});
      await this.$axios
        .get(url)
        .then((res) => {
          console.log("res :>> ", res);
          this.data = res.data;
        })
        .catch(() => {});
    },
    goToWeeklyHome() {
      this.$router.push({
        path: "/articleList",
      });
    },
    outLogin() {
      this.$store.dispatch("setItem", {
        userId: 0,
        salesInfo: {},
      });
      this.$tools.setStorage("saleLogin", {}, localStorage);
      this.$router.push({
        path: "/login",
      });
    },
    addExport() {
      this.$router.push({
        path: "/addDoctor",
      });
    },
    goToDetail(item) {
      this.$router.push({
        path: "/adminInfo",
        query: {
          id: item.id,
        },
      });
    },
  },
};
</script>

<style >
.adminMeet_container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: #f1f2f5;
  padding: 16px;
}
.adminMeet_head {
  background: #fff;
  flex: 140px 0 0;
  border-radius: 6px;
  padding: 14px 18px;
}
.adminMeet_title {
  font-weight: bold;
  font-size: 14px;
  color: #009d7d;
}
.adminMeet_head_block {
  margin-top: 14px;
  display: flex;
  justify-content: space-between;
}
.adminMeet_head_block_item {
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.adminMeet_head_block_item:last-child {
  border-right: none;
}
.adminMeet_head_avatar {
  width: 32px;
  height: 32px;
  border-radius: 50% 50%;
}
.adminMeet_head_number {
  font-weight: bold;
  font-size: 14px;
  color: #009d7d;
  line-height: 2;
}
.adminMeet_text {
  font-size: 14px;
  color: #606060;
}
.line {
  border: 0.1px solid#ccc;
  width: 0.1px;
}
.adminMeet_foot {
  flex: 50px 0 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.adminMeet_foot_btn {
  font-size: 14px;
  color: #606060;
  line-height: 35px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.adminMeet_foot_btn_icon {
  width: 21px;
}
.adminMeet_main {
  padding: 14px 18px;
  border-radius: 6px;
  margin: 20px 0;
  flex: 1 0 0;
  background: #fff;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.adminMeet_main_head {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.adminMeet_block {
  margin-top: 10px;
  /* height: 100%; */
  flex: 1 0 0;
  overflow: hidden;
}
.meetstarttable {
  position: relative;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.meettablethead {
  font-size: 14px;
  color: #009d7d;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ccc;
  padding: 6px 0;
}
.meettwo div {
  width: 100% !important;
}
.meettabletr_info {
  display: flex;
  line-height: 2.5;
  align-items: center;
}

.meettablethead .meettwo,
.meettabletr .meettwo {
  flex: 4 0 0;
  text-align: center;
}

.meettablethead .meetone,
.meettabletr .meetone {
  flex: 3 0 0;
  word-break: break-all;
}

.meettabletbody {
  position: relative;
  font-size: 14px;
  color: #606060;
  padding: 6px 0;
}
</style>